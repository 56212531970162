<template>
    <div id="app">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>
<style scoped>
    /* transaction effect routes */
    .fade-enter,
    .fade-leave-to {
        opacity: 0
    }

    .fade-enter-active,
    .fade-leave-active {
        -webkit-transition: opacity .2s;
        -o-transition: opacity .2s;
        transition: opacity .2s;
    }
</style>