import Vue from 'vue'
import App from './App.vue'

/* Config */
import config from './config/config'
Vue.prototype.$config = config

/* VueRouter */
import router from './router/routes'

/* Bootstrap v5.3.2 */
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

/* Vue Meta */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

/* Vue Loading Overlay */
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(VueLoading, {
    color: "#36E517",
    // backgroundColor: '#242933',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    loader: 'spinner',
    width: 60,
    height: 60,
    opacity: .5
});

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')