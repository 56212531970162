export default [
    /* Categorias */
    {
        path: '/categorias',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/apostas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        /* Apostar */
                        path: '/categorias/apostas/apostar',
                        name: 'painel.categorias.apostas.apostar',
                        component: () => import('@/views/pages/dashboard/mapa/components/apostas/AportarPage'),
                    },
                 
                ]
            },
            {
                path: '/vendas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        /* Apostar */
                        path: '/categorias/vendas/produto',
                        name: 'painel.categorias.vendas.produto',
                        component: () => import('@/views/pages/dashboard/mapa/components/vendas/ProdutoPage'),
                    },
                 
                ]
            },
        ]
    },
]