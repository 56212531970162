import Vue from 'vue'
import VueRouter from 'vue-router'
import routesAnunciar from './anunciar'
import routesCategorias from './categorias'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {
            name: 'auth.login'
        }
    },
    {
        path: '/entrar',
        name: 'auth.login',
        component: () => import('@/views/pages/auth/LoginPage'),
    },
    {
        path: '/cadastro',
        name: 'auth.register',
        component: () => import('@/views/pages/auth/RegisterPage'),
    },
    {
        path: '/recuperar-senha',
        name: 'auth.modificar-senha',
        component: () => import('@/views/pages/auth/ChangePassword'),
    },

    {
        path: '/mapa',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/home',
                name: 'painel.mapa.home',
                component: () => import('@/views/pages/dashboard/mapa/HomePage'),
            },
        ]
    },
    {
        path: '/favoritos',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.favoritos.index',
                component: () => import('@/views/pages/dashboard/favoritos/IndexPage'),
            },
        ]
    },
    {
        path: '/ponto',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.ponto.index',
                component: () => import('@/views/pages/dashboard/ponto/IndexPage'),
            },
            {
                path: '/editar-ponto',
                name: 'painel.ponto.editar',
                component: () => import('@/views/pages/dashboard/ponto/EditarPonto'),
            },
            {
                path: '/editar-ponto/veiculos',
                name: 'painel.ponto.veiculos',
                component: () => import('@/views/pages/dashboard/ponto/veiculos/IndexVeiculos'),
            },
            {
                path: '/editar-ponto/veiculos/editar/:id',
                name: 'painel.ponto.veiculos.editar',
                component: () => import('@/views/pages/dashboard/ponto/veiculos/EditarVeiculo'),
            },
        ],
    },

    {
        path: '/zap',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.zap.index',
                component: () => import('@/views/pages/dashboard/zap/IndexPage'),
            },
            {
                path: '/chat',
                name: 'painel.zap.chat',
                component: () => import('@/views/pages/dashboard/zap/ChatPage'),
            },
            {
                path: '/clientes',
                name: 'painel.zap.clientes',
                component: () => import('@/views/pages/dashboard/zap/ClientesPage'),
            },
            {
                path: '/fornecedores',
                name: 'painel.zap.fornecedores',
                component: () => import('@/views/pages/dashboard/zap/FornecedoresPage'),
            },
        ]
    },

    {
        path: '/carrinho',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.carrinho.index',
                component: () => import('@/views/pages/dashboard/carrinho/IndexPage'),
            },
        ]
    },

    {
        path: '/carteira',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.carteira.index',
                component: () => import('@/views/pages/dashboard/carteira/IndexPage'),
            },
        ]
    },

    {
        path: '/graficos',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.graficos.index',
                component: () => import('@/views/pages/dashboard/graficos/IndexPage'),
            },
        ]
    },

    {
        path: '/biometria',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.biometria.index',
                component: () => import('@/views/pages/dashboard/biometria/IndexPage'),
            },
        ]
    },

    {
        path: '/pedidos',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.pedidos.index',
                component: () => import('@/views/pages/dashboard/pedidos/IndexPage'),
            },
        ]
    },

    {
        path: '/compras',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/',
                name: 'painel.compras.index',
                component: () => import('@/views/pages/dashboard/compras/IndexPage'),
            },
            {
                path: '/compras/ver-compra',
                name: 'painel.compras.ver-compra',
                component: () => import('@/views/pages/dashboard/compras/VerCompra'),
            },
            {
                path: '/compras/editar-ou-escolher-outro-endereco',
                name: 'painel.compras.editar-endereco',
                component: () => import('@/views/pages/dashboard/compras/EditarEndereco'),
            },
            {
                path: '/compras/selecionar-forma-de-entrega',
                name: 'painel.compras.selecionar-forma-entrega',
                component: () => import('@/views/pages/dashboard/compras/SelecionarFormaEntrega'),
            },
            {
                path: '/compras/adicionar-um-novo-endereco',
                name: 'painel.compras.add-endereco',
                component: () => import('@/views/pages/dashboard/compras/AddEndereco'),
            },
            {
                path: '/compras/forma-de-pagamento',
                name: 'painel.compras.forma-pagamento',
                component: () => import('@/views/pages/dashboard/compras/FormaPagamento'),
            },
            {
                path: '/compras/prestacoes',
                name: 'painel.compras.prestacoes',
                component: () => import('@/views/pages/dashboard/compras/PrestacoesPage'),
            },
            {
                path: '/compras/confirmar-compra',
                name: 'painel.compras.confirmar-compra',
                component: () => import('@/views/pages/dashboard/compras/ConfirmarCompra'),
            },
            {
                path: '/compras/parte-estrategica',
                name: 'painel.compras.parte-estrategica',
                component: () => import('@/views/pages/dashboard/compras/ParteEstrategica'),
            },
            {
                path: '/compras/reclamacoes',
                name: 'painel.compras.reclamacoes.index',
                component: () => import('@/views/pages/dashboard/compras/reclamacoes/IndexPage'),
            },
            {
                path: '/compras/reclamacoes/iniciar-reclamacao',
                name: 'painel.compras.reclamacoes.iniciar-reclamacao',
                component: () => import('@/views/pages/dashboard/compras/reclamacoes/IniciarReclamacao'),
            },
            {
                path: '/compras/reclamacoes/acompanhar-reclamacao',
                name: 'painel.compras.reclamacoes.acompanhar-reclamacao',
                component: () => import('@/views/pages/dashboard/compras/reclamacoes/AcompanharReclamacao'),
            },
        ]
    },

    {
        path: '/conta',
        component: () => import('@/views/layouts/dashboard/dashboard1/Dashboard1Layout'),
        children: [
            {
                path: '/conta/compartilhar-qrcode',
                name: 'painel.conta.compartilhar-qrcode',
                component: () => import('@/views/pages/dashboard/conta/CompartilharQrcode'),
            },
            {
                path: '/conta/aparelhos/outros',
                name: 'painel.conta.aparelhos.outros',
                component: () => import('@/views/pages/dashboard/conta/aparelhos/OutrosAparelhos'),
            },
            {
                path: '/conta/aparelhos/adicionar',
                name: 'painel.conta.aparelhos.adicionar',
                component: () => import('@/views/pages/dashboard/conta/aparelhos/AdicionarAparelho'),
            },
            {
                path: '/conta/aparelhos/permissoes',
                name: 'painel.conta.aparelhos.permissoes',
                component: () => import('@/views/pages/dashboard/conta/aparelhos/PermissoesAparelho'),
            },
        ]
    },
    ...routesAnunciar,
    ...routesCategorias,
    

    // {
    //     path: '/cliente',
    //     component: () => import('@/views/layout/customer/CustomerLayout'),
    //     children: []
    // }
];

const router = new VueRouter({
    // mode: 'history',
    linkExactActiveClass: 'active',
    routes
})

export default router