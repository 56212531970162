export default [
    /* Anunciar */
    {
        path: '/anunciar',
        component: () => import('@/views/layouts/dashboard/dashboard2/Dashboard2Layout'),
        children: [
            {
                /* SOS */
                path: '/anunciar/sos/nome',
                name: 'painel.anunciar.sos.nome',
                component: () => import('@/views/pages/dashboard/anunciar/sos/NomePage'),
            },
            {
                /* Polícia */
                path: '/anunciar/policia/nome',
                name: 'painel.anunciar.policia.nome',
                component: () => import('@/views/pages/dashboard/anunciar/policia/NomePage'),
            },
            {
                /* prefeitura */
                path: '/anunciar/prefeitura/nome',
                name: 'painel.anunciar.prefeitura.nome',
                component: () => import('@/views/pages/dashboard/anunciar/prefeitura/NomePage'),
            },
            {
                /* ia */
                path: '/anunciar/ia/nome',
                name: 'painel.anunciar.ia.nome',
                component: () => import('@/views/pages/dashboard/anunciar/ia/NomePage'),
            },
            {
                /* noticias */
                path: '/anunciar/noticias/nome',
                name: 'painel.anunciar.noticias.nome',
                component: () => import('@/views/pages/dashboard/anunciar/noticias/NomePage'),
            },
            {
                /* Plano de Saúde */
                path: '/anunciar/plano-de-saude/nome',
                name: 'painel.anunciar.plano-de-saude.nome',
                component: () => import('@/views/pages/dashboard/anunciar/plano-de-saude/NomePage'),
            },
            {
                /* Hospitais */
                path: '/anunciar/hospitais/nome',
                name: 'painel.anunciar.hospitais.nome',
                component: () => import('@/views/pages/dashboard/anunciar/hospitais/NomePage'),
            },
            {
                /* Guincho */
                path: '/anunciar/guincho/nome',
                name: 'painel.anunciar.guincho.nome',
                component: () => import('@/views/pages/dashboard/anunciar/guincho/NomePage'),
            },
            {
                /* Engenheiros */
                path: '/anunciar/engenheiros/nome',
                name: 'painel.anunciar.engenheiros.nome',
                component: () => import('@/views/pages/dashboard/anunciar/engenheiros/NomePage'),
            },
            {
                /* Advogados */
                path: '/anunciar/advogados/nome',
                name: 'painel.anunciar.advogados.nome',
                component: () => import('@/views/pages/dashboard/anunciar/advogados/NomePage'),
            },
            {
                /* Médicos */
                path: '/anunciar/medicos/nome',
                name: 'painel.anunciar.medicos.nome',
                component: () => import('@/views/pages/dashboard/anunciar/medicos/NomePage'),
            },

            /* Cupons */
            {
                path: '/cupons',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/cupons/nome',
                        name: 'painel.anunciar.cupons.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/cupons/1NomePage'),
                    },
                    {
                        path: '/anunciar/cupons/categoria',
                        name: 'painel.anunciar.cupons.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/cupons/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/cupons/valor',
                        name: 'painel.anunciar.cupons.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/cupons/3ValorPage'),
                    },
                    {
                        path: '/anunciar/cupons/validade',
                        name: 'painel.anunciar.cupons.validade',
                        component: () => import('@/views/pages/dashboard/anunciar/cupons/4ValidadePage'),
                    },
                    {
                        path: '/anunciar/cupons/criado',
                        name: 'painel.anunciar.cupons.criado',
                        component: () => import('@/views/pages/dashboard/anunciar/cupons/5CriadoPage'),
                    },
                ]
            },

            {
                path: '/supermercado',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/supermercado/nome',
                        name: 'painel.anunciar.supermercado.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/1NomePage'),
                    },
                    {
                        path: '/anunciar/supermercado/categoria',
                        name: 'painel.anunciar.supermercado.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/supermercado/marca',
                        name: 'painel.anunciar.supermercado.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/supermercado/modelo',
                        name: 'painel.anunciar.supermercado.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/4ModeloPage'),
                    },
                    {
                        path: '/anunciar/supermercado/classificacao',
                        name: 'painel.anunciar.supermercado.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/5ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/supermercado/fotos',
                        name: 'painel.anunciar.supermercado.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/6FotosPage'),
                    },
                    {
                        path: '/anunciar/supermercado/descricao',
                        name: 'painel.anunciar.supermercado.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/7DescricaoPage'),
                    },
                    {
                        path: '/anunciar/supermercado/forma-de-entrega',
                        name: 'painel.anunciar.supermercado.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/8FormaEntrega'),
                    },
                    {
                        path: '/anunciar/supermercado/forma-de-garantia',
                        name: 'painel.anunciar.supermercado.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/9FormaGarantia'),
                    },
                    {
                        path: '/anunciar/supermercado/valor',
                        name: 'painel.anunciar.supermercado.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/10ValorPage'),
                    },
                    {
                        path: '/anunciar/supermercado/pronto',
                        name: 'painel.anunciar.supermercado.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/supermercado/11ProntoPage'),
                    },
                ]
            },

            {
                path: '/petshop',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/petshop/nome',
                        name: 'painel.anunciar.petshop.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/1NomePage'),
                    },
                    {
                        path: '/anunciar/petshop/categoria',
                        name: 'painel.anunciar.petshop.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/petshop/classificacao',
                        name: 'painel.anunciar.petshop.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/3ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/petshop/fotos',
                        name: 'painel.anunciar.petshop.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/4FotosPage'),
                    },
                    {
                        path: '/anunciar/petshop/descricao',
                        name: 'painel.anunciar.petshop.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/petshop/forma-de-entrega',
                        name: 'painel.anunciar.petshop.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/6FormaEntrega'),
                    },
                    {
                        path: '/anunciar/petshop/forma-de-garantia',
                        name: 'painel.anunciar.petshop.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/7FormaGarantia'),
                    },
                    {
                        path: '/anunciar/petshop/valor',
                        name: 'painel.anunciar.petshop.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/8ValorPage'),
                    },
                    {
                        path: '/anunciar/petshop/pronto',
                        name: 'painel.anunciar.petshop.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/petshop/9ProntoPage'),
                    },
                ]
            },
            /* Beleza */
            {
                path: '/beleza',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/beleza/nome',
                        name: 'painel.anunciar.beleza.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/1NomePage'),
                    },
                    {
                        path: '/anunciar/beleza/categoria',
                        name: 'painel.anunciar.beleza.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/beleza/classificacao',
                        name: 'painel.anunciar.beleza.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/3ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/beleza/fotos',
                        name: 'painel.anunciar.beleza.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/4FotosPage'),
                    },
                    {
                        path: '/anunciar/beleza/descricao',
                        name: 'painel.anunciar.beleza.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/beleza/forma-de-entrega',
                        name: 'painel.anunciar.beleza.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/6FormaEntrega'),
                    },
                    {
                        path: '/anunciar/beleza/forma-de-garantia',
                        name: 'painel.anunciar.beleza.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/7FormaGarantia'),
                    },
                    {
                        path: '/anunciar/beleza/valor',
                        name: 'painel.anunciar.beleza.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/8ValorPage'),
                    },
                    {
                        path: '/anunciar/beleza/pronto',
                        name: 'painel.anunciar.beleza.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/beleza/9ProntoPage'),
                    },
                ]
            },
            /* Relacionamento */
            {
                path: '/relacionamento',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/relacionamento/nome',
                        name: 'painel.anunciar.relacionamento.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/1NomePage'),
                    },
                    {
                        path: '/anunciar/relacionamento/aniversario',
                        name: 'painel.anunciar.relacionamento.aniversario',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/2AniversarioPage'),
                    },
                    {
                        path: '/anunciar/relacionamento/profissao',
                        name: 'painel.anunciar.relacionamento.profissao',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/3ProfissaoPage'),
                    },
                    {
                        path: '/anunciar/relacionamento/características',
                        name: 'painel.anunciar.relacionamento.características',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/4CaracteristicasPage'),
                    },
                    {
                        path: '/anunciar/relacionamento/fotos',
                        name: 'painel.anunciar.relacionamento.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/5FotosPage'),
                    },
                    {
                        path: '/anunciar/relacionamento/coisas-que-gosta',
                        name: 'painel.anunciar.relacionamento.coisas-que-gosta',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/6CoisasGosta'),
                    },
                    {
                        path: '/anunciar/relacionamento/curriculo',
                        name: 'painel.anunciar.relacionamento.curriculo',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/7CurriculoPage'),
                    },
                    {
                        path: '/anunciar/relacionamento/raio-de-interesse',
                        name: 'painel.anunciar.relacionamento.raio-de-interesse',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/8RaioInteresse'),
                    },
                    {
                        path: '/anunciar/relacionamento/pronto',
                        name: 'painel.anunciar.relacionamento.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/relacionamento/9PontoPage'),
                    },
                ]
            },
            /* Promocoes */
            {
                path: '/promocoes',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/promocoes/selecionar-anuncio',
                        name: 'painel.anunciar.promocoes.selecionar-anuncio',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/1SelecionarAnuncio'),
                    },
                    {
                        path: '/anunciar/promocoes/nome',
                        name: 'painel.anunciar.promocoes.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/2NomePage'),
                    },
                    {
                        path: '/anunciar/promocoes/desconto',
                        name: 'painel.anunciar.promocoes.desconto',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/3DescontoPage'),
                    },
                    {
                        path: '/anunciar/promocoes/tempo',
                        name: 'painel.anunciar.promocoes.tempo',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/4TempoPage'),
                    },
                    {
                        path: '/anunciar/promocoes/entrega',
                        name: 'painel.anunciar.promocoes.entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/5EntregaPage'),
                    },
                    {
                        path: '/anunciar/promocoes/pronto',
                        name: 'painel.anunciar.promocoes.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/promocoes/6ProntoPage'),
                    },
                ]
            },
            /* Trocas */
            {
                path: '/trocas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/trocas/nome',
                        name: 'painel.anunciar.trocas.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/1NomePage'),
                    },
                    {
                        path: '/anunciar/trocas/categoria',
                        name: 'painel.anunciar.trocas.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/trocas/marca',
                        name: 'painel.anunciar.trocas.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/trocas/modelo',
                        name: 'painel.anunciar.trocas.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/4ModeloPage'),
                    },
                    {
                        path: '/anunciar/trocas/classificacao',
                        name: 'painel.anunciar.trocas.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/5ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/trocas/fotos',
                        name: 'painel.anunciar.trocas.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/6FotosPage'),
                    },
                    {
                        path: '/anunciar/trocas/descricao',
                        name: 'painel.anunciar.trocas.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/7DescricaoPage'),
                    },
                    {
                        path: '/anunciar/trocas/indicar-produto',
                        name: 'painel.anunciar.trocas.indicar-produto',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/8IndicarProduto'),
                    },
                    {
                        path: '/anunciar/trocas/forma-de-entrega',
                        name: 'painel.anunciar.trocas.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/9FormaEntrega'),
                    },
                    {
                        path: '/anunciar/trocas/forma-de-garantia',
                        name: 'painel.anunciar.trocas.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/10FormaGarantia'),
                    },
                    {
                        path: '/anunciar/trocas/valor',
                        name: 'painel.anunciar.trocas.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/11ValorPage'),
                    },
                    {
                        path: '/anunciar/trocas/pronto',
                        name: 'painel.anunciar.trocas.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/trocas/12ProntoPage'),
                    },
                ]
            },
            /* Leilão */
            {
                path: '/leilao',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/leilao/nome',
                        name: 'painel.anunciar.leilao.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/1NomePage'),
                    },
                    {
                        path: '/anunciar/leilao/categoria',
                        name: 'painel.anunciar.leilao.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/leilao/marca',
                        name: 'painel.anunciar.leilao.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/leilao/modelo',
                        name: 'painel.anunciar.leilao.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/4ModeloPage'),
                    },
                    {
                        path: '/anunciar/leilao/classificacao',
                        name: 'painel.anunciar.leilao.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/5ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/leilao/fotos',
                        name: 'painel.anunciar.leilao.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/6FotosPage'),
                    },
                    {
                        path: '/anunciar/leilao/descricao',
                        name: 'painel.anunciar.leilao.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/7DescricaoPage'),
                    },
                    {
                        path: '/anunciar/leilao/forma-de-entrega',
                        name: 'painel.anunciar.leilao.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/8FormaEntrega'),
                    },
                    {
                        path: '/anunciar/leilao/forma-de-garantia',
                        name: 'painel.anunciar.leilao.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/9FormaGarantia'),
                    },
                    {
                        path: '/anunciar/leilao/valor',
                        name: 'painel.anunciar.leilao.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/10ValorPage'),
                    },
                    {
                        path: '/anunciar/leilao/pronto',
                        name: 'painel.anunciar.leilao.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/11ProntoPage'),
                    },
                    {
                        path: '/anunciar/leilao/adicionar-estragegia',
                        name: 'painel.anunciar.leilao.adicionar-estragegia',
                        component: () => import('@/views/pages/dashboard/anunciar/leilao/12EstrategiaVenda'),
                    },
                ]
            },
            /* Rifas */
            {
                path: '/rifas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/rifas/nome',
                        name: 'painel.anunciar.rifas.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/1NomePage'),
                    },
                    {
                        path: '/anunciar/rifas/categoria',
                        name: 'painel.anunciar.rifas.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/rifas/premios',
                        name: 'painel.anunciar.rifas.premios',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/3PremiosPage'),
                    },
                    {
                        path: '/anunciar/rifas/fotos',
                        name: 'painel.anunciar.rifas.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/4FotosPage'),
                    },
                    {
                        path: '/anunciar/rifas/descricao',
                        name: 'painel.anunciar.rifas.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/rifas/modelo',
                        name: 'painel.anunciar.rifas.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/6ModeloPage'),
                    },
                    {
                        path: '/anunciar/rifas/forma-de-entrega',
                        name: 'painel.anunciar.rifas.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/7FormaEntrega'),
                    },
                    {
                        path: '/anunciar/rifas/forma-de-garantia',
                        name: 'painel.anunciar.rifas.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/8FormaGarantia'),
                    },
                    {
                        path: '/anunciar/rifas/valor',
                        name: 'painel.anunciar.rifas.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/9ValorPage'),
                    },
                    {
                        path: '/anunciar/rifas/pronto',
                        name: 'painel.anunciar.rifas.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/rifas/10ProntoPage'),
                    },
                ]
            },
            /* Doações */
            {
                path: '/doacoes',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/doacoes/nome',
                        name: 'painel.anunciar.doacoes.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/1NomePage'),
                    },
                    {
                        path: '/anunciar/doacoes/categoria',
                        name: 'painel.anunciar.doacoes.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/doacoes/marca',
                        name: 'painel.anunciar.doacoes.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/doacoes/classificacao',
                        name: 'painel.anunciar.doacoes.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/4ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/doacoes/fotos',
                        name: 'painel.anunciar.doacoes.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/5FotosPage'),
                    },
                    {
                        path: '/anunciar/doacoes/descricao',
                        name: 'painel.anunciar.doacoes.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/6DescricaoPage'),
                    },
                    {
                        path: '/anunciar/doacoes/forma-de-entrega',
                        name: 'painel.anunciar.doacoes.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/7FormaEntrega'),
                    },
                    {
                        path: '/anunciar/doacoes/forma-de-garantia',
                        name: 'painel.anunciar.doacoes.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/8FormaGarantia'),
                    },
                    {
                        path: '/anunciar/doacoes/valor',
                        name: 'painel.anunciar.doacoes.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/9ValorPage'),
                    },
                    {
                        path: '/anunciar/doacoes/pronto',
                        name: 'painel.anunciar.doacoes.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/doacoes/10ProntoPage'),
                    },
                ]
            },
            /* Rastreamento */
            {
                path: '/rastreamento',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/rastreamento/nome',
                        name: 'painel.anunciar.rastreamento.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/rastreamento/1NomePage'),
                    },
                    {
                        path: '/anunciar/rastreamento/fotos',
                        name: 'painel.anunciar.rastreamento.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/rastreamento/2FotosPage'),
                    },
                    {
                        path: '/anunciar/rastreamento/recompensa',
                        name: 'painel.anunciar.rastreamento.recompensa',
                        component: () => import('@/views/pages/dashboard/anunciar/rastreamento/3RecompensaPage'),
                    },
                    {
                        path: '/anunciar/rastreamento/cadastrado',
                        name: 'painel.anunciar.rastreamento.cadastrado',
                        component: () => import('@/views/pages/dashboard/anunciar/rastreamento/4CadastradoPage'),
                    },
                    {
                        path: '/anunciar/rastreamento/pronto',
                        name: 'painel.anunciar.rastreamento.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/rastreamento/5ProntoPage'),
                    },
                ]
            },
            /* Passagens */
            {
                path: '/passagens',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/passagens/indicar-pontos',
                        name: 'painel.anunciar.passagens.indicar-pontos',
                        component: () => import('@/views/pages/dashboard/anunciar/passagens/1IndicarPontos'),
                    },
                    {
                        path: '/anunciar/passagens/horario-da-partida',
                        name: 'painel.anunciar.passagens.horario-da-partida',
                        component: () => import('@/views/pages/dashboard/anunciar/passagens/2HorarioPartida'),
                    },
                    {
                        path: '/anunciar/passagens/veiculo',
                        name: 'painel.anunciar.passagens.veiculo',
                        component: () => import('@/views/pages/dashboard/anunciar/passagens/3VeiculoPage'),
                    },
                    {
                        path: '/anunciar/passagens/assentos',
                        name: 'painel.anunciar.passagens.assentos',
                        component: () => import('@/views/pages/dashboard/anunciar/passagens/4AssentosPage'),
                    },
                    {
                        path: '/anunciar/passagens/valor',
                        name: 'painel.anunciar.passagens.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/passagens/5ValorPage'),
                    },
                ]
            },
            /* Saude */
            {
                path: '/saude',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/saude/nome',
                        name: 'painel.anunciar.saude.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/1NomePage'),
                    },
                    {
                        path: '/anunciar/saude/categoria',
                        name: 'painel.anunciar.saude.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/saude/fotos',
                        name: 'painel.anunciar.saude.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/3FotosPage'),
                    },
                    {
                        path: '/anunciar/saude/descricao',
                        name: 'painel.anunciar.saude.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/4DescricaoPage'),
                    },
                    {
                        path: '/anunciar/saude/forma-de-entrega',
                        name: 'painel.anunciar.saude.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/5FormaEntrega'),
                    },
                    {
                        path: '/anunciar/saude/horario-de-funcionamento',
                        name: 'painel.anunciar.saude.horario-de-funcionamento',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/6HorarioFuncionamento'),
                    },
                    {
                        path: '/anunciar/saude/oferecer-beneficios',
                        name: 'painel.anunciar.saude.oferecer-beneficios',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/7OferecerBeneficios'),
                    },
                    {
                        path: '/anunciar/saude/valor',
                        name: 'painel.anunciar.saude.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/8ValorPage'),
                    },
                    {
                        path: '/anunciar/saude/pronto',
                        name: 'painel.anunciar.saude.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/saude/9ProntoPage'),
                    },
                ]
            },
            /* farmacia */
            {
                path: '/farmacia',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/farmacia/nome',
                        name: 'painel.anunciar.farmacia.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/1NomePage'),
                    },
                    {
                        path: '/anunciar/farmacia/fabricante',
                        name: 'painel.anunciar.farmacia.fabricante',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/2FabricantePage'),
                    },
                    {
                        path: '/anunciar/farmacia/categoria',
                        name: 'painel.anunciar.farmacia.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/3CategoriaPage'),
                    },
                    {
                        path: '/anunciar/farmacia/fotos',
                        name: 'painel.anunciar.farmacia.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/4FotosPage'),
                    },
                    {
                        path: '/anunciar/farmacia/descricao',
                        name: 'painel.anunciar.farmacia.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/farmacia/forma-de-entrega',
                        name: 'painel.anunciar.farmacia.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/6FormaEntrega'),
                    },
                    {
                        path: '/anunciar/farmacia/beneficios',
                        name: 'painel.anunciar.farmacia.beneficios',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/7BeneficiosPage'),
                    },
                    {
                        path: '/anunciar/farmacia/valor',
                        name: 'painel.anunciar.farmacia.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/8ValorPage'),
                    },
                    {
                        path: '/anunciar/farmacia/pronto',
                        name: 'painel.anunciar.farmacia.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/farmacia/9ProntoPage'),
                    },
                ]
            },
            /* comidas */
            {
                path: '/comidas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/comidas/nome',
                        name: 'painel.anunciar.comidas.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/1NomePage'),
                    },
                    {
                        path: '/anunciar/comidas/categoria',
                        name: 'painel.anunciar.comidas.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/comidas/classificacao',
                        name: 'painel.anunciar.comidas.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/3ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/comidas/fotos',
                        name: 'painel.anunciar.comidas.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/4FotosPage'),
                    },
                    {
                        path: '/anunciar/comidas/descricao',
                        name: 'painel.anunciar.comidas.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/comidas/horario',
                        name: 'painel.anunciar.comidas.horario',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/6HorarioPage'),
                    },
                    {
                        path: '/anunciar/comidas/forma-de-entrega',
                        name: 'painel.anunciar.comidas.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/7FormaEntrega'),
                    },
                    {
                        path: '/anunciar/comidas/forma-de-garantia',
                        name: 'painel.anunciar.comidas.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/8FormaGarantia'),
                    },
                    {
                        path: '/anunciar/comidas/valor',
                        name: 'painel.anunciar.comidas.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/9ValorPage'),
                    },
                    {
                        path: '/anunciar/comidas/pronto',
                        name: 'painel.anunciar.comidas.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/comidas/10ProntoPage'),
                    },
                ]
            },
            /* hospedagem */
            {
                path: '/hospedagem',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/hospedagem/nome',
                        name: 'painel.anunciar.hospedagem.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/1NomePage'),
                    },
                    {
                        path: '/anunciar/hospedagem/localizacao',
                        name: 'painel.anunciar.hospedagem.localizacao',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/2LocalizacaoPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/informacoes',
                        name: 'painel.anunciar.hospedagem.informacoes',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/3InformacoesPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/categoria',
                        name: 'painel.anunciar.hospedagem.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/4CategoriaPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/fotos',
                        name: 'painel.anunciar.hospedagem.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/5FotosPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/descricao',
                        name: 'painel.anunciar.hospedagem.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/6DescricaoPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/beneficios',
                        name: 'painel.anunciar.hospedagem.beneficios',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/7BeneficiosPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/horario',
                        name: 'painel.anunciar.hospedagem.horario',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/8HorarioPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/valor',
                        name: 'painel.anunciar.hospedagem.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/9ValorPage'),
                    },
                    {
                        path: '/anunciar/hospedagem/pronto',
                        name: 'painel.anunciar.hospedagem.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/hospedagem/10ProntoPage'),
                    },
                ]
            },
            /* frete */
            {
                path: '/frete',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/frete/foto',
                        name: 'painel.anunciar.frete.foto',
                        component: () => import('@/views/pages/dashboard/anunciar/frete/1FotoPage'),
                    },
                    {
                        path: '/anunciar/frete/características',
                        name: 'painel.anunciar.frete.características',
                        component: () => import('@/views/pages/dashboard/anunciar/frete/2CaracteristicasPage'),
                    },
                    {
                        path: '/anunciar/frete/documentos',
                        name: 'painel.anunciar.frete.documentos',
                        component: () => import('@/views/pages/dashboard/anunciar/frete/3DocumentosPage'),
                    },
                    {
                        path: '/anunciar/frete/indicar-veiculo',
                        name: 'painel.anunciar.frete.indicar-veiculo',
                        component: () => import('@/views/pages/dashboard/anunciar/frete/4IndicarVeiculo'),
                    },
                    {
                        path: '/anunciar/frete/finalizado',
                        name: 'painel.anunciar.frete.finalizado',
                        component: () => import('@/views/pages/dashboard/anunciar/frete/5FinalizadoPage'),
                    },
                ]
            },
            /* motorista */
            {
                path: '/motorista',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/motorista/foto',
                        name: 'painel.anunciar.motorista.foto',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/1FotoPage'),
                    },
                    {
                        path: '/anunciar/motorista/caracteristicas',
                        name: 'painel.anunciar.motorista.caracteristicas',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/2CaracteristicasPage'),
                    },
                    {
                        path: '/anunciar/motorista/documentos',
                        name: 'painel.anunciar.motorista.documentos',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/3DocumentosPage'),
                    },
                    {
                        path: '/anunciar/motorista/documentos-do-veiculo',
                        name: 'painel.anunciar.motorista.documentos-do-veiculo',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/4DocumentosVeiculo'),
                    },
                    {
                        path: '/anunciar/motorista/indicar-combustivel',
                        name: 'painel.anunciar.motorista.indicar-combustivel',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/5IndicarCombustivel'),
                    },
                    {
                        path: '/anunciar/motorista/total-de-portas-do-veiculo',
                        name: 'painel.anunciar.motorista.total-de-portas-do-veiculo',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/6TotalPortas'),
                    },
                    {
                        path: '/anunciar/motorista/total-de-lugares-no-veiculo',
                        name: 'painel.anunciar.motorista.total-de-lugares-no-veiculo',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/7TotalLugares'),
                    },
                    {
                        path: '/anunciar/motorista/tipo-de-carroceria',
                        name: 'painel.anunciar.motorista.tipo-de-carroceria',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/8TipoCarroceria'),
                    },
                    {
                        path: '/anunciar/motorista/fotos',
                        name: 'painel.anunciar.motorista.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/9FotosPage'),
                    },
                    {
                        path: '/anunciar/motorista/finalizado',
                        name: 'painel.anunciar.motorista.finalizado',
                        component: () => import('@/views/pages/dashboard/anunciar/motorista/10FinalizadoPage'),
                    },
                ]
            },
            /* locacao veiculo */
            {
                path: '/locacao-veiculo',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/locacao-de-veiculo/nome',
                        name: 'painel.anunciar.locacao-de-veiculo.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/1NomePage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/marca',
                        name: 'painel.anunciar.locacao-de-veiculo.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/2MarcaPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/modelo',
                        name: 'painel.anunciar.locacao-de-veiculo.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/3ModeloPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/ano-de-fabricacao',
                        name: 'painel.anunciar.locacao-de-veiculo.ano-de-fabricacao',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/4AnoFabricacao'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/indique-o-combustivel',
                        name: 'painel.anunciar.locacao-de-veiculo.indique-o-combustivel',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/5IndicarCombustivel'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/total-de-portas',
                        name: 'painel.anunciar.locacao-de-veiculo.total-de-portas',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/6TotalPortas'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/total-de-lugares',
                        name: 'painel.anunciar.locacao-de-veiculo.total-de-lugares',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/7TotalLugares'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/tipo-de-transmissao',
                        name: 'painel.anunciar.locacao-de-veiculo.tipo-de-transmissao',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/8TipoTransmissao'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/tipo-de-motor',
                        name: 'painel.anunciar.locacao-de-veiculo.tipo-de-motor',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/9TipoMotor'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/tipo-de-carroceria',
                        name: 'painel.anunciar.locacao-de-veiculo.tipo-de-carroceria',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/10TipoCarroceria'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/quantidade-de-quilometros',
                        name: 'painel.anunciar.locacao-de-veiculo.quantidade-de-quilometros',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/11QuantidadeKm'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/fotos',
                        name: 'painel.anunciar.locacao-de-veiculo.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/12FotosPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/descricao',
                        name: 'painel.anunciar.locacao-de-veiculo.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/13DescricaoPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/categoria',
                        name: 'painel.anunciar.locacao-de-veiculo.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/14CategoriaPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/forma-de-entrega',
                        name: 'painel.anunciar.locacao-de-veiculo.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/15FormaEntrega'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/beneficios',
                        name: 'painel.anunciar.locacao-de-veiculo.beneficios',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/16BeneficiosPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/horario-de-funcionamento',
                        name: 'painel.anunciar.locacao-de-veiculo.horario-de-funcionamento',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/17HorarioFuncionamento'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/valor',
                        name: 'painel.anunciar.locacao-de-veiculo.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/18ValorPage'),
                    },
                    {
                        path: '/anunciar/locacao-de-veiculo/pronto',
                        name: 'painel.anunciar.locacao-de-veiculo.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao-veiculo/19ProntoPage'),
                    },
                ]
            },
            /* Locação */
            {
                path: '/locacao',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/locacao/nome',
                        name: 'painel.anunciar.locacao.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/1NomePage'),
                    },
                    {
                        path: '/anunciar/locacao/categoria',
                        name: 'painel.anunciar.locacao.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/locacao/marca',
                        name: 'painel.anunciar.locacao.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/locacao/modelo',
                        name: 'painel.anunciar.locacao.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/4ModeloPage'),
                    },
                    {
                        path: '/anunciar/locacao/classificacao',
                        name: 'painel.anunciar.locacao.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/5ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/locacao/fotos',
                        name: 'painel.anunciar.locacao.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/6FotosPage'),
                    },
                    {
                        path: '/anunciar/locacao/descricao',
                        name: 'painel.anunciar.locacao.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/7DescricaoPage'),
                    },
                    {
                        path: '/anunciar/locacao/forma-de-entrega',
                        name: 'painel.anunciar.locacao.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/8FormaEntrega'),
                    },
                    {
                        path: '/anunciar/locacao/forma-de-garantia',
                        name: 'painel.anunciar.locacao.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/9FormaGarantia'),
                    },
                    {
                        path: '/anunciar/locacao/valor',
                        name: 'painel.anunciar.locacao.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/10ValorPage'),
                    },
                    {
                        path: '/anunciar/locacao/pronto',
                        name: 'painel.anunciar.locacao.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/locacao/11ProntoPage'),
                    },
                ]
            },
            /* Serviços */
            {
                path: '/servicos',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/servicos/nome',
                        name: 'painel.anunciar.servicos.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/1NomePage'),
                    },
                    {
                        path: '/anunciar/servicos/categoria',
                        name: 'painel.anunciar.servicos.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/servicos/classificacao',
                        name: 'painel.anunciar.servicos.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/3ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/servicos/fotos',
                        name: 'painel.anunciar.servicos.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/4FotosPage'),
                    },
                    {
                        path: '/anunciar/servicos/descricao',
                        name: 'painel.anunciar.servicos.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/5DescricaoPage'),
                    },
                    {
                        path: '/anunciar/servicos/forma-de-entrega',
                        name: 'painel.anunciar.servicos.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/6FormaEntrega'),
                    },
                    {
                        path: '/anunciar/servicos/forma-de-garantia',
                        name: 'painel.anunciar.servicos.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/7FormaGarantia'),
                    },
                    {
                        path: '/anunciar/servicos/valor',
                        name: 'painel.anunciar.servicos.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/8ValorPage'),
                    },
                    {
                        path: '/anunciar/servicos/pronto',
                        name: 'painel.anunciar.servicos.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/9ProntoPage'),
                    },
                    {
                        path: '/anunciar/servicos/estrategias-a-venda',
                        name: 'painel.anunciar.servicos.estrategias-a-venda',
                        component: () => import('@/views/pages/dashboard/anunciar/servicos/10EstrategiaVenda'),
                    },
                ]
            },
            /* Consórcio */
            {
                path: '/consorcio',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/consorcio/nome',
                        name: 'painel.anunciar.consorcio.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/consorcio/1NomePage'),
                    },
                ]
            },
            /* Vendas */
            {
                path: '/vendas',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/vendas/nome',
                        name: 'painel.anunciar.vendas.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/1NomePage'),
                    },
                    {
                        path: '/anunciar/vendas/categoria',
                        name: 'painel.anunciar.vendas.categoria',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/2CategoriaPage'),
                    },
                    {
                        path: '/anunciar/vendas/marca',
                        name: 'painel.anunciar.vendas.marca',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/3MarcaPage'),
                    },
                    {
                        path: '/anunciar/vendas/modelo',
                        name: 'painel.anunciar.vendas.modelo',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/4ModeloPage'),
                    },
                    {
                        path: '/anunciar/vendas/classificacao',
                        name: 'painel.anunciar.vendas.classificacao',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/5ClassificacaoPage'),
                    },
                    {
                        path: '/anunciar/vendas/fotos',
                        name: 'painel.anunciar.vendas.fotos',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/6FotosPage'),
                    },
                    {
                        path: '/anunciar/vendas/descricao',
                        name: 'painel.anunciar.vendas.descricao',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/7DescricaoPage'),
                    },
                    {
                        path: '/anunciar/vendas/forma-de-entrega',
                        name: 'painel.anunciar.vendas.forma-de-entrega',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/8FormaEntrega'),
                    },
                    {
                        path: '/anunciar/vendas/forma-de-garantia',
                        name: 'painel.anunciar.vendas.forma-de-garantia',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/9FormaGarantia'),
                    },
                    {
                        path: '/anunciar/vendas/valor',
                        name: 'painel.anunciar.vendas.valor',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/10ValorPage'),
                    },
                    {
                        path: '/anunciar/vendas/pronto',
                        name: 'painel.anunciar.vendas.pronto',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/11ProntoPage'),
                    },
                    {
                        path: '/anunciar/vendas/estrategias-a-venda',
                        name: 'painel.anunciar.vendas.estrategias-a-venda',
                        component: () => import('@/views/pages/dashboard/anunciar/vendas/12EstrategiaVenda'),
                    },
                ]
            },
            /* Seguros */
            {
                path: '/seguros',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/seguros/nome',
                        name: 'painel.anunciar.seguros.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/seguros/1NomePage'),
                    },
                ]
            },
            /* Ingresso */
            {
                path: '/ingresso',
                component: () => import('@/views/layouts/LayoutEmpty'),
                children: [
                    {
                        path: '/anunciar/ingresso/nome',
                        name: 'painel.anunciar.ingresso.nome',
                        component: () => import('@/views/pages/dashboard/anunciar/ingresso/1NomePage'),
                    },
                ]
            }
        ]
    },
]